<!--
 * @Description:车行道停车运营管理 运营管理 收费规则 charingRules
 * @Author: hj
 * @Date: 2021-03-16 14:12:45
 * @LastEditTime: 2023-02-06 09:58:53
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mianbody">
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="100px"
                          label="计费规则名称">
              <el-input v-model="searchForm.billingRuleDefName"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="handleClickQuery">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>计费规则信息</span>
        <el-button class="tableTitleButton"
                   @click="chargeRuleAdd"
                   v-show="insert_user"
                   type="success">新 增</el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="是否免费">
              <template slot-scope="scope">
                <!--1为收费、 2为免费 -->
                <el-switch v-model="scope.row.chargeStatusCode"
                           inactive-color="#ff4949"
                           active-color="#13ce66"
                           :inactive-value='1'
                           :active-value='2'
                           :disabled = "isDisabled"
                           @change="changeSwitch(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8"
                        class="buttonLine">
                  <span style="color: #34b2e9;font-size:14px"
                        @click="fetchDetail(scope.row)">查 看</span>
                </el-col>
                <el-col :span="8"
                        class="buttonLine">
                  <span style="color: #34b2e9;font-size:14px"
                        v-show="click_update"
                        @click="editDetail(scope.row)">编 辑</span>
                </el-col>
                <el-col :span="8"
                        class="buttonLine">
                  <span style="color: #34b2e9;font-size:14px"
                        v-show="click_delete"
                        @click="handleDelete(scope.row)">删 除</span>
                </el-col>

              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 页面新增弹出框 -->
    <el-dialog title="新增收费规则"
               :visible.sync="addFormListVisible"
               top="7vh"
               :append-to-body="true"
               :destroy-on-close="true">
      <el-row class="addRow">
        <el-row class="spanLine1">
          <span>计费规则信息</span>
        </el-row>
        <el-form :model="addBillRuleFormList"
                 :inline="true"
                 :rules="rules"
                 size="small">
          <el-row class="addFormLine1">
            <el-col :span="12">
              <el-form-item label="计费规则类型"
                            label-width="140px"
                            prop="billRuleTypeName">
                <el-select v-model="addBillRuleFormList.billRuleTypeName"
                           @change="handleChangeBillRuleName"
                           placeholder="请选择计费规则类型"
                           disabled
                           class="dt-form-width">
                  <el-option v-for="item in addBillRuleDictList"
                             :key="item.code"
                             :label="item.name"
                             :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="计费规则名称"
                            prop="billingRuleDefName"
                            class="col-right"
                            label-width="140px">
                <el-input v-model="addBillRuleFormList.billingRuleDefName"
                          class="dt-form-width"
                          placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="计费规则描述"
                            label-width="140px"
                            prop="remark">
                <el-input v-model="addBillRuleFormList.remark"
                          class="dt-form-width"
                          placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 按时长计费选择vif -->
          <el-row v-if="addBillRuleFormList.billRuleTypeName=='按时长收费'">
            <!-- 按时长收费大小型车辆 -->
            <el-row class="addFormLineXize">
              <span>按时收费细则</span>
            </el-row>
            <el-row class="smaBigBut">
              <el-col :span="19"
                      style="display:flex">
                <el-button type="info"
                           size="small"
                           @click="handleClickAddSmallCar">小型车辆</el-button>
                <el-button type="success"
                           size="small"
                           @click="handleClickAddBigCar">大型车辆</el-button>
              </el-col>
            </el-row>
            <!-- 小型车辆 -->
            <el-row v-if="addBillRuleFormList.bindCarType == '小型车辆'"
                    class="addSmallRow">
              <el-row class="addSmallRow-daySpan">
                <span>小型车辆白天计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    v-model="addBillRuleFormList.billingRuleDetailList[0].dayBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    v-model="addBillRuleFormList.billingRuleDetailList[0].dayEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                prop="dayBillingPrice"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[0].dayBillingPrice"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                prop="dayFreeDuration"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[0].dayFreeDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                prop="dayMinBillingDuration"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[0].dayMinBillingDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                prop="dayCeilingAmount"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[0].dayCeilingAmount"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row style="background:#00487A;width:100%;height:1px"></el-row>
              <el-row class="addSmallRow-daySpan">
                <span>小型车辆夜间计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    v-model="addBillRuleFormList.billingRuleDetailList[0].nightBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    v-model="addBillRuleFormList.billingRuleDetailList[0].nightEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                prop="nightBillingPrice"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[0].nightBillingPrice"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                prop="nightFreeDuration"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[0].nightFreeDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                prop="nightMinBillingDuration"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[0].nightMinBillingDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                prop="nightCeilingAmount"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[0].nightCeilingAmount"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
            <!-- 大型车辆 -->
            <el-row v-if="addBillRuleFormList.bindCarType == '大型车辆'"
                    class="addSmallRow">
              <el-row class="addSmallRow-daySpan">
                <span>大型车辆白天计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    v-model="addBillRuleFormList.billingRuleDetailList[1].dayBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    v-model="addBillRuleFormList.billingRuleDetailList[1].dayEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                prop="dayBillingPrice"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[1].dayBillingPrice"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                prop="dayFreeDuration"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[1].dayFreeDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                prop="dayMinBillingDuration"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[1].dayMinBillingDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                prop="dayCeilingAmount"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[1].dayCeilingAmount"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row style="background:#00487A;width:100%;height:1px"></el-row>
              <el-row class="addSmallRow-daySpan">
                <span>大型车辆夜间计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    v-model="addBillRuleFormList.billingRuleDetailList[1].nightBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    v-model="addBillRuleFormList.billingRuleDetailList[1].nightEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                prop="nightBillingPrice"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[1].nightBillingPrice"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                prop="nightFreeDuration"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[1].nightFreeDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                prop="nightMinBillingDuration"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[1].nightMinBillingDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                prop="nightCeilingAmount"
                                label-width="140px">
                    <el-input v-model="addBillRuleFormList.billingRuleDetailList[1].nightCeilingAmount"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
          </el-row>
        </el-form>
      </el-row>
      <!-- 新增确认按钮 -->
      <el-row slot="footer"
              class="addFooter">
        <el-button type="primary"
                   @click="handleClickAddSubmit">保 存</el-button>
        <el-button type="info"
                   @click="handleClickAddCancle">取 消</el-button>
      </el-row>
    </el-dialog>

    <!-- 页面查看弹出框 -->
    <el-dialog title="查看收费规则详情"
               :visible.sync="watchFormListVisible"
               :append-to-body="true"
               :destroy-on-close="true">
      <el-row class="addRow">
        <el-form :model="watchBillRuleFormList"
                 :inline="true"
                 size="small">
          <!-- 按时长计费选择vif -->
          <el-row v-if="watchBillRuleFormList.billRuleTypeName=='按时长收费'">
            <!-- 按时长收费大小型车辆 -->
            <el-row class="smaBigBut">
              <el-col :span="19"
                      style="display:flex">
                <el-button type="info"
                           size="small"
                           @click="handleClickWatchSmallCar">小型车辆</el-button>
                <el-button type="success"
                           size="small"
                           @click="handleClickWatchBigCar">大型车辆</el-button>
              </el-col>
            </el-row>
            <!-- 小型车辆 -->
            <el-row v-if="watchBillRuleFormList.bindCarType == '小型车辆'"
                    class="addSmallRow">
              <el-row class="addSmallRow-daySpan">
                <span>小型车辆白天计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    disabled
                                    v-model="watchBillRuleFormList.billingRuleDetailList[0].dayBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    disabled
                                    v-model="watchBillRuleFormList.billingRuleDetailList[0].dayEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[0].dayBillingPrice"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[0].dayFreeDuration"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[0].dayMinBillingDuration"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[0].dayCeilingAmount"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row style="background:#00487A;width:100%;height:1px"></el-row>
              <el-row class="addSmallRow-daySpan">
                <span>小型车辆夜间计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    disabled
                                    v-model="watchBillRuleFormList.billingRuleDetailList[0].nightBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    disabled
                                    v-model="watchBillRuleFormList.billingRuleDetailList[0].nightEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[0].nightBillingPrice"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[0].nightFreeDuration"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[0].nightMinBillingDuration"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[0].nightCeilingAmount"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
            <!-- 大型车辆 -->
            <el-row v-if="watchBillRuleFormList.bindCarType == '大型车辆'"
                    class="addSmallRow">
              <el-row class="addSmallRow-daySpan">
                <span>大型车辆白天计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    disabled
                                    v-model="watchBillRuleFormList.billingRuleDetailList[1].dayBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    disabled
                                    v-model="watchBillRuleFormList.billingRuleDetailList[1].dayEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[1].dayBillingPrice"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[1].dayFreeDuration"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[1].dayMinBillingDuration"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[1].dayCeilingAmount"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row style="background:#00487A;width:100%;height:1px"></el-row>
              <el-row class="addSmallRow-daySpan">
                <span>大型车辆夜间计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    disabled
                                    v-model="watchBillRuleFormList.billingRuleDetailList[1].nightBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    disabled
                                    v-model="watchBillRuleFormList.billingRuleDetailList[1].nightEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[1].nightBillingPrice"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[1].nightFreeDuration"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[1].nightMinBillingDuration"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                label-width="140px">
                    <el-input v-model="watchBillRuleFormList.billingRuleDetailList[1].nightCeilingAmount"
                              class="dt-form-width"
                              readonly
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
          </el-row>
        </el-form>
      </el-row>
      <!-- 查看返回按钮 -->
      <el-row slot="footer"
              style="margin-left:92%">
        <el-button type="info"
                   @click="handleClickWatchBack">返 回</el-button>
      </el-row>
    </el-dialog>

    <!-- 页面修改弹出框 -->
    <el-dialog title="修改收费规则"
               :visible.sync="editFormListVisible"
               top="7vh"
               @close="queryTableList"
               :append-to-body="true"
               :destroy-on-close="true">
      <el-row class="addRow">
        <el-row class="spanLine1">
          <span>计费规则信息</span>
        </el-row>
        <el-form :model="editBillRuleFormList"
                 :inline="true"
                 size="small">
          <el-row class="addFormLine1">
            <el-col :span="12">
              <el-form-item label="计费规则类型"
                            label-width="140px">
                <el-select v-model="editBillRuleFormList.billRuleTypeName"
                           @change="handleChangeBillRuleName"
                           disabled
                           placeholder="请选择计费规则类型"
                           class="dt-form-width">
                  <el-option v-for="item in addBillRuleDictList"
                             :key="item.code"
                             :label="item.name"
                             :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="计费规则名称"
                            class="col-right"
                            label-width="140px">
                <el-input v-model="editBillRuleFormList.billingRuleDefName"
                          class="dt-form-width"
                          placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="计费规则描述"
                            label-width="140px">
                <el-input v-model="editBillRuleFormList.remark"
                          class="dt-form-width"
                          placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 按时长计费选择vif -->
          <el-row v-if="editBillRuleFormList.billRuleTypeName=='按时长收费'">
            <!-- 按时长收费大小型车辆 -->
            <el-row class="addFormLineXize">
              <span>按时收费细则</span>
            </el-row>
            <el-row class="smaBigBut">
              <el-col :span="19"
                      style="display:flex">
                <el-button type="info"
                           size="small"
                           @click="handleClickEditSmallCar">小型车辆</el-button>
                <el-button type="success"
                           size="small"
                           @click="handleClickEditBigCar">大型车辆</el-button>
              </el-col>
            </el-row>
            <!-- 小型车辆 -->
            <el-row v-if="editBillRuleFormList.bindCarType == '小型车辆'"
                    class="addSmallRow">
              <el-row class="addSmallRow-daySpan">
                <span>小型车辆白天计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    v-model="editBillRuleFormList.billingRuleDetailList[0].dayBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    v-model="editBillRuleFormList.billingRuleDetailList[0].dayEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[0].dayBillingPrice"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[0].dayFreeDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[0].dayMinBillingDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[0].dayCeilingAmount"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row style="background:#00487A;width:100%;height:1px"></el-row>
              <el-row class="addSmallRow-daySpan">
                <span>小型车辆夜间计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    v-model="editBillRuleFormList.billingRuleDetailList[0].nightBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    v-model="editBillRuleFormList.billingRuleDetailList[0].nightEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[0].nightBillingPrice"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[0].nightFreeDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[0].nightMinBillingDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[0].nightCeilingAmount"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
            <!-- 大型车辆 -->
            <el-row v-if="editBillRuleFormList.bindCarType == '大型车辆'"
                    class="addSmallRow">
              <el-row class="addSmallRow-daySpan">
                <span>大型车辆白天计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    v-model="editBillRuleFormList.billingRuleDetailList[1].dayBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    v-model="editBillRuleFormList.billingRuleDetailList[1].dayEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[1].dayBillingPrice"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[1].dayFreeDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[1].dayMinBillingDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[1].dayCeilingAmount"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row style="background:#00487A;width:100%;height:1px"></el-row>
              <el-row class="addSmallRow-daySpan">
                <span>大型车辆夜间计费</span>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="计费时段"
                                label-width="140px">
                    <el-time-select style="width:125px"
                                    placeholder="起始时间"
                                    v-model="editBillRuleFormList.billingRuleDetailList[1].nightBeginTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00'
                                    }">
                    </el-time-select>
                    <el-time-select style="width:125px"
                                    placeholder="结束时间"
                                    v-model="editBillRuleFormList.billingRuleDetailList[1].nightEndTime"
                                    :picker-options="{
                                      start: '00:00',
                                      step: '01:00',
                                      end: '24:00',
                                    }">
                    </el-time-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="金额(元/半小时)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[1].nightBillingPrice"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="免费停车时长(分)"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[1].nightFreeDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="最短计费时长(分)"
                                class="col-right"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[1].nightMinBillingDuration"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row class="addSmallRow-dayrow">
                <el-col :span="12">
                  <el-form-item label="封顶计费金额(元)"
                                label-width="140px">
                    <el-input v-model="editBillRuleFormList.billingRuleDetailList[1].nightCeilingAmount"
                              class="dt-form-width"
                              placeholder="请输入"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
          </el-row>
        </el-form>
      </el-row>
      <!-- 修改确认按钮 -->
      <el-row slot="footer"
              class="addFooter">
        <el-button type="primary"
                   @click="handleClickEditSubmit">保 存</el-button>
        <el-button type="info"
                   @click="handleClickEditCancle">取 消</el-button>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    return {
      isDisabled:true,
      // 全局表格
      tableConfig: {
        border: true,
        'cell-style': { background: ' transparent !important' },
      },
      tableFun: {
        'selection-change': this.handleSelectionChange,
      },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: {
        pageNum: 1,
        pageSize: 10,
        billingRuleDefName: '',
        chargName: '',
        operName: '',
      },
      tableColumnList: [
        {
          prop: 'billingRuleDefId',
          label: '计费规则编号',
        },
        {
          prop: 'billingRuleDefName',
          label: '计费规则名称',
        },
        {
          prop: 'billingRuleTypeName',
          label: '计费规则类型名称',
        },
        {
          prop: 'remark',
          label: '描述',
        },
        {
          prop: 'chargeStatusDesc',
          label: '收费类型',
        }
      ],
      tableList: {
        list: [],
      },// 全局表格结束

      // 搜索框内容
      searchForm: {
        billingRuleDefName: '',//计费规则名称
      },

      pageSize: 15,//分页大小
      pageNum: 1,//默认查询

      addFormListVisible: false,// 新增弹窗显示

      addBillRuleFormList: {
        bindCarType: "小型车辆",//选择车辆类型
        billRuleTypeName: '按时长收费',//计费规则类型名称
        billingRuleType: 2,//	计费规则类型（按时长计费）
        billingRuleDefName: "",//计费规则名称
        remark: "",//备注
        //新增下的小型车辆表格数据
        billingRuleDetailList: [
          {
            vehicleTypeCode: 1,//车辆类型编码
            dayBeginTime: "08:00",//白天开始时间
            dayEndTime: "20:00",//白天结束时间
            dayBillingPrice: 2.5,//白天收费金额
            dayFreeDuration: 0,//白天免费时长
            dayMinBillingDuration: 30,//白天最短计费时长
            dayCeilingAmount: 0,//白天封顶金额(0则不封顶)
            nightBeginTime: "20:00",//夜间开始时间
            nightEndTime: "08:00",//夜间结束时间
            nightBillingPrice: 2,//夜间收费金额
            nightFreeDuration: 0,//夜间免费时长
            nightMinBillingDuration: 30,//夜间最短计费时长
            nightCeilingAmount: 10,//夜间封顶金额
          },
          {
            vehicleTypeCode: 2,//车辆类型编码
            dayBeginTime: "08:00",//白天开始时间
            dayEndTime: "20:00",//白天结束时间
            dayBillingPrice: 3.5,//白天收费金额
            dayFreeDuration: 0,//白天免费时长
            dayMinBillingDuration: 30,//白天最短计费时长
            dayCeilingAmount: 0,//白天封顶金额(0则不封顶)
            nightBeginTime: "20:00",//夜间开始时间
            nightEndTime: "08:00",//夜间结束时间
            nightBillingPrice: 2,//夜间收费金额
            nightFreeDuration: 0,//夜间免费时长
            nightMinBillingDuration: 30,//夜间最短计费时长
            nightCeilingAmount: 10,//夜间封顶金额
          }
        ],
      },
      addBillRuleDictList: [],  //新增计费规则下拉框
      addMinTimeDictList: [],//新增最短计费时间下拉框

      rules: {
        billingRuleDefName: [
          { required: true, message: '请输入计费规则名称', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请输入计费规则描述', trigger: 'blur' }
        ],
        dayBillingPrice: [
          { required: true, message: '请输入计费金额', trigger: 'blur' }
        ],
        dayFreeDuration: [
          { required: true, message: '请输入免费停车时长', trigger: 'blur' }
        ],
        dayMinBillingDuration: [
          { required: true, message: '请输入最短计费时长', trigger: 'blur' }
        ],
        dayCeilingAmount: [
          { required: true, message: '请输入封顶计费金额', trigger: 'blur' }
        ],
        nightBillingPrice: [
          { required: true, message: '请输入计费金额', trigger: 'blur' }
        ],
        nightFreeDuration: [
          { required: true, message: '请输入免费停车时长', trigger: 'blur' }
        ],
        nightMinBillingDuration: [
          { required: true, message: '请输入最短计费时长', trigger: 'blur' }
        ],
        nightCeilingAmount: [
          { required: true, message: '请输入封顶计费金额', trigger: 'blur' }
        ],
      },

      watchFormListVisible: false,// 查看弹窗显示

      watchBillRuleFormList: {
        bindCarType: "小型车辆",//选择车辆类型
        billRuleTypeName: '按时长收费',//计费规则类型名称
        billingRuleType: 2,//	计费规则类型（按时长计费）
        billingRuleDefName: "",//计费规则名称
        remark: "",//备注
        //查看下的小型车辆表格数据
        billingRuleDetailList: [
          {
            vehicleTypeCode: 1,//车辆类型编码
            dayBeginTime: "",//白天开始时间
            dayEndTime: "",//白天结束时间
            dayBillingPrice: "",//白天收费金额
            dayFreeDuration: "",//白天免费时长
            dayMinBillingDuration: "",//白天最短计费时长
            dayCeilingAmount: "",//白天封顶金额(0则不封顶)
            nightBeginTime: "",//夜间开始时间
            nightEndTime: "",//夜间结束时间
            nightBillingPrice: "",//夜间收费金额
            nightFreeDuration: "",//夜间免费时长
            nightMinBillingDuration: "",//夜间最短计费时长
            nightCeilingAmount: "",//夜间封顶金额
          },
          {
            vehicleTypeCode: 2,//车辆类型编码
            dayBeginTime: "",//白天开始时间
            dayEndTime: "",//白天结束时间
            dayBillingPrice: "",//白天收费金额
            dayFreeDuration: "",//白天免费时长
            dayMinBillingDuration: "",//白天最短计费时长
            dayCeilingAmount: "",//白天封顶金额(0则不封顶)
            nightBeginTime: "",//夜间开始时间
            nightEndTime: "",//夜间结束时间
            nightBillingPrice: "",//夜间收费金额
            nightFreeDuration: "",//夜间免费时长
            nightMinBillingDuration: "",//夜间最短计费时长
            nightCeilingAmount: "",//夜间封顶金额
          }
        ],
      },

      editFormListVisible: false,// 修改弹窗显示

      editBillRuleFormList: {
        bindCarType: "小型车辆",//选择车辆类型
        billRuleTypeName: '按时长收费',//计费规则类型名称
        billingRuleType: 2,//	计费规则类型（按时长计费）
        billingRuleDefId: "",//计费规则id
        billingRuleDefName: "",//计费规则名称
        remark: "",//备注
        //修改下的小型车辆表格数据
        billingRuleDetailList: [
          {
            vehicleTypeCode: 1,//车辆类型编码
            dayBeginTime: "",//白天开始时间
            dayEndTime: "",//白天结束时间
            dayBillingPrice: "",//白天收费金额
            dayFreeDuration: "",//白天免费时长
            dayMinBillingDuration: "",//白天最短计费时长
            dayCeilingAmount: "",//白天封顶金额(0则不封顶)
            nightBeginTime: "",//夜间开始时间
            nightEndTime: "",//夜间结束时间
            nightBillingPrice: "",//夜间收费金额
            nightFreeDuration: "",//夜间免费时长
            nightMinBillingDuration: "",//夜间最短计费时长
            nightCeilingAmount: "",//夜间封顶金额
          },
          {
            vehicleTypeCode: 2,//车辆类型编码
            dayBeginTime: "",//白天开始时间
            dayEndTime: "",//白天结束时间
            dayBillingPrice: "",//白天收费金额
            dayFreeDuration: "",//白天免费时长
            dayMinBillingDuration: "",//白天最短计费时长
            dayCeilingAmount: "",//白天封顶金额(0则不封顶)
            nightBeginTime: "",//夜间开始时间
            nightEndTime: "",//夜间结束时间
            nightBillingPrice: "",//夜间收费金额
            nightFreeDuration: "",//夜间免费时长
            nightMinBillingDuration: "",//夜间最短计费时长
            nightCeilingAmount: "",//夜间封顶金额
          }
        ],
      },
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //获取菜单按钮权限
    this.buttonList = localStorage.getItem('buttonListByUser')
    this.buttonShow();
    // 查询收费规则列表
    this.queryTableList()
    //获取计费规则
    this.getChargingRules()
    //获取最短计费时长
    this.getMinimumBillingTime()
  },
  //方法集合
  methods: {
    //菜单按钮展示权限
    buttonShow () {
      const buttonMenuName = JSON.parse(this.buttonList)
      const menuNames = [];
      for (let i = 0; i < Object.keys(buttonMenuName).length; i++) {
        const menuName = buttonMenuName[i].menuName;
        menuNames.push(menuName);
        if (menuNames[i] === '新增') {
          this.insert_user = true
        }
        else if (menuNames[i] === '编辑') {
          this.click_update = true
        }
        else if (menuNames[i] === '删除') {
          this.click_delete = true
        }
        else if (menuNames[i] === '是否免费') {
          this.isDisabled = false
        }
      }
    },
    // 是否收费
    changeSwitch (row) {
      if (row.chargeStatusCode === 1) {
        let info = {
          billingRuleDefId: row.billingRuleDefId,
          chargeStatusCode: 1
        }
        this.$charingRules.exchangeChargeStatus(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({ message: '设置收费成功', type: 'success' });
            this.queryTableList()
          }
        })
      }
      else if (row.chargeStatusCode === 2) {
        let info = {
          billingRuleDefId: row.billingRuleDefId,
          chargeStatusCode: 2
        }
        this.$charingRules.exchangeChargeStatus(info).then(response => {
          if (response.resultCode === '2000') {
            this.$message({ message: '设置免费成功', type: 'success' });
            this.queryTableList()
          }
        })
      }
    },
    // 页面新增按钮
    chargeRuleAdd () {
      this.addFormListVisible = true
      this.addBillRuleFormList.billRuleTypeName = "按时长收费"
      this.addBillRuleFormList.billingRuleDetailList[0].minBillingDurationCode = "1"
      this.addBillRuleFormList.billingRuleDetailList[1].minBillingDurationCode = "1"
      if (this.addBillRuleFormList.billRuleTypeName == "按时长收费") {
        this.addBillRuleFormList.bindCarType = "小型车辆"
      }
    },
    // 新增中的确认按钮
    handleClickAddSubmit () {
      this.addBillRuleFormList.billingRuleDetailList[0].dayBeginTime = Number(this.addBillRuleFormList.billingRuleDetailList[0].dayBeginTime.slice(0, 2)) * 60
      this.addBillRuleFormList.billingRuleDetailList[0].dayEndTime = Number(this.addBillRuleFormList.billingRuleDetailList[0].dayEndTime.slice(0, 2)) * 60
      this.addBillRuleFormList.billingRuleDetailList[0].nightBeginTime = Number(this.addBillRuleFormList.billingRuleDetailList[0].nightBeginTime.slice(0, 2)) * 60
      this.addBillRuleFormList.billingRuleDetailList[0].nightEndTime = Number(this.addBillRuleFormList.billingRuleDetailList[0].nightEndTime.slice(0, 2)) * 60 + 1440
      this.addBillRuleFormList.billingRuleDetailList[1].dayBeginTime = Number(this.addBillRuleFormList.billingRuleDetailList[1].dayBeginTime.slice(0, 2)) * 60
      this.addBillRuleFormList.billingRuleDetailList[1].dayEndTime = Number(this.addBillRuleFormList.billingRuleDetailList[1].dayEndTime.slice(0, 2)) * 60
      this.addBillRuleFormList.billingRuleDetailList[1].nightBeginTime = Number(this.addBillRuleFormList.billingRuleDetailList[1].nightBeginTime.slice(0, 2)) * 60
      this.addBillRuleFormList.billingRuleDetailList[1].nightEndTime = Number(this.addBillRuleFormList.billingRuleDetailList[1].nightEndTime.slice(0, 2)) * 60 + 1440
      this.addBillRuleFormList.billingRuleDetailList[0].dayBillingPrice = Number(this.addBillRuleFormList.billingRuleDetailList[0].dayBillingPrice) * 100
      this.addBillRuleFormList.billingRuleDetailList[0].dayCeilingAmount = Number(this.addBillRuleFormList.billingRuleDetailList[0].dayCeilingAmount) * 100
      this.addBillRuleFormList.billingRuleDetailList[0].nightBillingPrice = Number(this.addBillRuleFormList.billingRuleDetailList[0].nightBillingPrice) * 100
      this.addBillRuleFormList.billingRuleDetailList[0].nightCeilingAmount = Number(this.addBillRuleFormList.billingRuleDetailList[0].nightCeilingAmount) * 100
      this.addBillRuleFormList.billingRuleDetailList[1].dayBillingPrice = Number(this.addBillRuleFormList.billingRuleDetailList[1].dayBillingPrice) * 100
      this.addBillRuleFormList.billingRuleDetailList[1].dayCeilingAmount = Number(this.addBillRuleFormList.billingRuleDetailList[1].dayCeilingAmount) * 100
      this.addBillRuleFormList.billingRuleDetailList[1].nightBillingPrice = Number(this.addBillRuleFormList.billingRuleDetailList[1].nightBillingPrice) * 100
      this.addBillRuleFormList.billingRuleDetailList[1].nightCeilingAmount = Number(this.addBillRuleFormList.billingRuleDetailList[1].nightCeilingAmount) * 100
      let info = {
        billingRuleType: 2,
        billingRuleDefName: this.addBillRuleFormList.billingRuleDefName,
        remark: this.addBillRuleFormList.remark,
        billingRuleDetailList: [
          {
            vehicleTypeCode: 1,
            dayBeginTime: this.addBillRuleFormList.billingRuleDetailList[0].dayBeginTime,//白天开始时间
            dayEndTime: this.addBillRuleFormList.billingRuleDetailList[0].dayEndTime,//白天结束时间
            dayBillingPrice: this.addBillRuleFormList.billingRuleDetailList[0].dayBillingPrice,//白天收费金额
            dayFreeDuration: this.addBillRuleFormList.billingRuleDetailList[0].dayFreeDuration,//白天免费时长
            dayMinBillingDuration: this.addBillRuleFormList.billingRuleDetailList[0].dayMinBillingDuration,//白天最短计费时长
            dayCeilingAmount: this.addBillRuleFormList.billingRuleDetailList[0].dayCeilingAmount,//白天封顶金额(0则不封顶)
            nightBeginTime: this.addBillRuleFormList.billingRuleDetailList[0].nightBeginTime,//夜间开始时间
            nightEndTime: this.addBillRuleFormList.billingRuleDetailList[0].nightEndTime,//夜间结束时间
            nightBillingPrice: this.addBillRuleFormList.billingRuleDetailList[0].nightBillingPrice,//夜间收费金额
            nightFreeDuration: this.addBillRuleFormList.billingRuleDetailList[0].nightFreeDuration,//夜间免费时长
            nightMinBillingDuration: this.addBillRuleFormList.billingRuleDetailList[0].nightMinBillingDuration,//夜间最短计费时长
            nightCeilingAmount: this.addBillRuleFormList.billingRuleDetailList[0].nightCeilingAmount,//夜间封顶金额
          },
          {
            vehicleTypeCode: 2,
            dayBeginTime: this.addBillRuleFormList.billingRuleDetailList[1].dayBeginTime,//白天开始时间
            dayEndTime: this.addBillRuleFormList.billingRuleDetailList[1].dayEndTime,//白天结束时间
            dayBillingPrice: this.addBillRuleFormList.billingRuleDetailList[1].dayBillingPrice,//白天收费金额
            dayFreeDuration: this.addBillRuleFormList.billingRuleDetailList[1].dayFreeDuration,//白天免费时长
            dayMinBillingDuration: this.addBillRuleFormList.billingRuleDetailList[1].dayMinBillingDuration,//白天最短计费时长
            dayCeilingAmount: this.addBillRuleFormList.billingRuleDetailList[1].dayCeilingAmount,//白天封顶金额(0则不封顶)
            nightBeginTime: this.addBillRuleFormList.billingRuleDetailList[1].nightBeginTime,//夜间开始时间
            nightEndTime: this.addBillRuleFormList.billingRuleDetailList[1].nightEndTime,//夜间结束时间
            nightBillingPrice: this.addBillRuleFormList.billingRuleDetailList[1].nightBillingPrice,//夜间收费金额
            nightFreeDuration: this.addBillRuleFormList.billingRuleDetailList[1].nightFreeDuration,//夜间免费时长
            nightMinBillingDuration: this.addBillRuleFormList.billingRuleDetailList[1].nightMinBillingDuration,//夜间最短计费时长
            nightCeilingAmount: this.addBillRuleFormList.billingRuleDetailList[1].nightCeilingAmount,//夜间封顶金额
          }
        ]
      }
      this.$charingRules.addBillingRule(info).then(() => {
        this.addFormListVisible = false;
        window.location.reload()
      })
    },
    // 新增中的取消按钮
    handleClickAddCancle () {
      this.addFormListVisible = false;
      this.queryTableList();
    },
    // 新增中的点击大型车
    handleClickAddBigCar () {
      this.addBillRuleFormList.bindCarType = "大型车辆"
    },
    // 新增中的点击小型车
    handleClickAddSmallCar () {
      this.addBillRuleFormList.bindCarType = "小型车辆"
    },
    // 点击查看按钮
    fetchDetail (row) {
      this.watchFormListVisible = true
      let info = {
        billingRuleDefId: row.billingRuleDefId,
        billingRuleType: row.billingRuleType
      }
      this.$charingRules.queryBillingRuleDetail(info).then(res => {
        res.resultEntity[0].dayBeginTime = Number(res.resultEntity[0].dayBeginTime) / 60 + ":00"
        res.resultEntity[0].dayEndTime = Number(res.resultEntity[0].dayEndTime) / 60 + ":00"
        res.resultEntity[0].nightBeginTime = Number(res.resultEntity[0].nightBeginTime) / 60 + ":00"
        res.resultEntity[0].nightEndTime = Number(Number(res.resultEntity[0].nightEndTime) / 60 - 24) + ":00"
        res.resultEntity[1].dayBeginTime = Number(res.resultEntity[1].dayBeginTime) / 60 + ":00"
        res.resultEntity[1].dayEndTime = Number(res.resultEntity[1].dayEndTime) / 60 + ":00"
        res.resultEntity[1].nightBeginTime = Number(res.resultEntity[1].nightBeginTime) / 60 + ":00"
        res.resultEntity[1].nightEndTime = Number(Number(res.resultEntity[1].nightEndTime) / 60 - 24) + ":00"
        res.resultEntity[0].dayBillingPrice = Number(res.resultEntity[0].dayBillingPrice) / 100
        res.resultEntity[0].dayCeilingAmount = Number(res.resultEntity[0].dayCeilingAmount) / 100
        res.resultEntity[0].nightBillingPrice = Number(res.resultEntity[0].nightBillingPrice) / 100
        res.resultEntity[0].nightCeilingAmount = Number(res.resultEntity[0].nightCeilingAmount) / 100
        res.resultEntity[1].dayBillingPrice = Number(res.resultEntity[1].dayBillingPrice) / 100
        res.resultEntity[1].dayCeilingAmount = Number(res.resultEntity[1].dayCeilingAmount) / 100
        res.resultEntity[1].nightBillingPrice = Number(res.resultEntity[1].nightBillingPrice) / 100
        res.resultEntity[1].nightCeilingAmount = Number(res.resultEntity[1].nightCeilingAmount) / 100
        this.watchBillRuleFormList.billingRuleDetailList = res.resultEntity
      })
    },
    // 查看中的返回按钮
    handleClickWatchBack () {
      this.watchFormListVisible = false
    },
    // 查看中的点击大型车
    handleClickWatchBigCar () {
      this.watchBillRuleFormList.bindCarType = "大型车辆"
    },
    // 查看中的点击小型车
    handleClickWatchSmallCar () {
      this.watchBillRuleFormList.bindCarType = "小型车辆"
    },
    // 修改按钮
    editDetail (row) {
      this.editBillRuleFormList.billingRuleDefId = row.billingRuleDefId
      this.editBillRuleFormList.billingRuleDefName = row.billingRuleDefName
      this.editBillRuleFormList.remark = row.remark
      this.editFormListVisible = true
      let info = {
        billingRuleDefId: row.billingRuleDefId,
        billingRuleType: row.billingRuleType
      }
      this.$charingRules.queryBillingRuleDetail(info).then(res => {
        res.resultEntity[0].dayBeginTime = Number(res.resultEntity[0].dayBeginTime || 0) / 60 + ":00"
        res.resultEntity[0].dayEndTime = Number(res.resultEntity[0].dayEndTime || 0) / 60 + ":00"
        res.resultEntity[0].nightBeginTime = Number(res.resultEntity[0].nightBeginTime) / 60 + ":00"
        res.resultEntity[0].nightEndTime = Number(Number(res.resultEntity[0].nightEndTime) / 60 - 24) + ":00"
        res.resultEntity[1].dayBeginTime = Number(res.resultEntity[1].dayBeginTime) / 60 + ":00"
        res.resultEntity[1].dayEndTime = Number(res.resultEntity[1].dayEndTime) / 60 + ":00"
        res.resultEntity[1].nightBeginTime = Number(res.resultEntity[1].nightBeginTime) / 60 + ":00"
        res.resultEntity[1].nightEndTime = Number(Number(res.resultEntity[1].nightEndTime) / 60 - 24) + ":00"
        res.resultEntity[0].dayBillingPrice = Number(res.resultEntity[0].dayBillingPrice) / 100
        res.resultEntity[0].dayCeilingAmount = Number(res.resultEntity[0].dayCeilingAmount) / 100
        res.resultEntity[0].nightBillingPrice = Number(res.resultEntity[0].nightBillingPrice) / 100
        res.resultEntity[0].nightCeilingAmount = Number(res.resultEntity[0].nightCeilingAmount) / 100
        res.resultEntity[1].dayBillingPrice = Number(res.resultEntity[1].dayBillingPrice) / 100
        res.resultEntity[1].dayCeilingAmount = Number(res.resultEntity[1].dayCeilingAmount) / 100
        res.resultEntity[1].nightBillingPrice = Number(res.resultEntity[1].nightBillingPrice) / 100
        res.resultEntity[1].nightCeilingAmount = Number(res.resultEntity[1].nightCeilingAmount) / 100
        this.editBillRuleFormList.billingRuleDetailList = res.resultEntity
      })
    },
    // 修改中的确认按钮
    handleClickEditSubmit () {
      this.editBillRuleFormList.billingRuleDetailList[0].dayBeginTime = this.editBillRuleFormList.billingRuleDetailList[0].dayBeginTime.split(":")[0] * 60
      this.editBillRuleFormList.billingRuleDetailList[0].dayEndTime = this.editBillRuleFormList.billingRuleDetailList[0].dayEndTime.split(":")[0] * 60
      this.editBillRuleFormList.billingRuleDetailList[0].nightBeginTime = this.editBillRuleFormList.billingRuleDetailList[0].nightBeginTime.split(":")[0] * 60
      this.editBillRuleFormList.billingRuleDetailList[0].nightEndTime = this.editBillRuleFormList.billingRuleDetailList[0].nightEndTime.split(":")[0] * 60 + 1440
      this.editBillRuleFormList.billingRuleDetailList[1].dayBeginTime = Number(this.editBillRuleFormList.billingRuleDetailList[1].dayBeginTime.split(":")[0] * 60)
      this.editBillRuleFormList.billingRuleDetailList[1].dayEndTime = this.editBillRuleFormList.billingRuleDetailList[1].dayEndTime.split(":")[0] * 60
      this.editBillRuleFormList.billingRuleDetailList[1].nightBeginTime = this.editBillRuleFormList.billingRuleDetailList[1].nightBeginTime.split(":")[0] * 60
      this.editBillRuleFormList.billingRuleDetailList[1].nightEndTime = this.editBillRuleFormList.billingRuleDetailList[1].nightEndTime.split(":")[0] * 60 + 1440
      this.editBillRuleFormList.billingRuleDetailList[0].dayBillingPrice = Number(this.editBillRuleFormList.billingRuleDetailList[0].dayBillingPrice) * 100
      this.editBillRuleFormList.billingRuleDetailList[0].dayCeilingAmount = Number(this.editBillRuleFormList.billingRuleDetailList[0].dayCeilingAmount) * 100
      this.editBillRuleFormList.billingRuleDetailList[0].nightBillingPrice = Number(this.editBillRuleFormList.billingRuleDetailList[0].nightBillingPrice) * 100
      this.editBillRuleFormList.billingRuleDetailList[0].nightCeilingAmount = Number(this.editBillRuleFormList.billingRuleDetailList[0].nightCeilingAmount) * 100
      this.editBillRuleFormList.billingRuleDetailList[1].dayBillingPrice = Number(this.editBillRuleFormList.billingRuleDetailList[1].dayBillingPrice) * 100
      this.editBillRuleFormList.billingRuleDetailList[1].dayCeilingAmount = Number(this.editBillRuleFormList.billingRuleDetailList[1].dayCeilingAmount) * 100
      this.editBillRuleFormList.billingRuleDetailList[1].nightBillingPrice = Number(this.editBillRuleFormList.billingRuleDetailList[1].nightBillingPrice) * 100
      this.editBillRuleFormList.billingRuleDetailList[1].nightCeilingAmount = Number(this.editBillRuleFormList.billingRuleDetailList[1].nightCeilingAmount) * 100
      let info = {
        billingRuleType: 2,
        billingRuleDefId: this.editBillRuleFormList.billingRuleDefId,
        billingRuleDefName: this.editBillRuleFormList.billingRuleDefName,
        remark: this.editBillRuleFormList.remark,
        billingRuleDetailList: [
          {
            vehicleTypeCode: 1,
            dayBeginTime: this.editBillRuleFormList.billingRuleDetailList[0].dayBeginTime,//白天开始时间
            dayEndTime: this.editBillRuleFormList.billingRuleDetailList[0].dayEndTime,//白天结束时间
            dayBillingPrice: this.editBillRuleFormList.billingRuleDetailList[0].dayBillingPrice,//白天收费金额
            dayFreeDuration: this.editBillRuleFormList.billingRuleDetailList[0].dayFreeDuration,//白天免费时长
            dayMinBillingDuration: this.editBillRuleFormList.billingRuleDetailList[0].dayMinBillingDuration,//白天最短计费时长
            dayCeilingAmount: this.editBillRuleFormList.billingRuleDetailList[0].dayCeilingAmount,//白天封顶金额(0则不封顶)
            nightBeginTime: this.editBillRuleFormList.billingRuleDetailList[0].nightBeginTime,//夜间开始时间
            nightEndTime: this.editBillRuleFormList.billingRuleDetailList[0].nightEndTime,//夜间结束时间
            nightBillingPrice: this.editBillRuleFormList.billingRuleDetailList[0].nightBillingPrice,//夜间收费金额
            nightFreeDuration: this.editBillRuleFormList.billingRuleDetailList[0].nightFreeDuration,//夜间免费时长
            nightMinBillingDuration: this.editBillRuleFormList.billingRuleDetailList[0].nightMinBillingDuration,//夜间最短计费时长
            nightCeilingAmount: this.editBillRuleFormList.billingRuleDetailList[0].nightCeilingAmount,//夜间封顶金额
          },
          {
            vehicleTypeCode: 2,
            dayBeginTime: this.editBillRuleFormList.billingRuleDetailList[1].dayBeginTime,//白天开始时间
            dayEndTime: this.editBillRuleFormList.billingRuleDetailList[1].dayEndTime,//白天结束时间
            dayBillingPrice: this.editBillRuleFormList.billingRuleDetailList[1].dayBillingPrice,//白天收费金额
            dayFreeDuration: this.editBillRuleFormList.billingRuleDetailList[1].dayFreeDuration,//白天免费时长
            dayMinBillingDuration: this.editBillRuleFormList.billingRuleDetailList[1].dayMinBillingDuration,//白天最短计费时长
            dayCeilingAmount: this.editBillRuleFormList.billingRuleDetailList[1].dayCeilingAmount,//白天封顶金额(0则不封顶)
            nightBeginTime: this.editBillRuleFormList.billingRuleDetailList[1].nightBeginTime,//夜间开始时间
            nightEndTime: this.editBillRuleFormList.billingRuleDetailList[1].nightEndTime,//夜间结束时间
            nightBillingPrice: this.editBillRuleFormList.billingRuleDetailList[1].nightBillingPrice,//夜间收费金额
            nightFreeDuration: this.editBillRuleFormList.billingRuleDetailList[1].nightFreeDuration,//夜间免费时长
            nightMinBillingDuration: this.editBillRuleFormList.billingRuleDetailList[1].nightMinBillingDuration,//夜间最短计费时长
            nightCeilingAmount: this.editBillRuleFormList.billingRuleDetailList[1].nightCeilingAmount,//夜间封顶金额
          }
        ]
      }
      this.$charingRules.updateBillingRule(info).then(() => {
        this.editFormListVisible = false;
        this.queryTableList();
      })
    },
    // 修改中的取消按钮
    handleClickEditCancle () {
      this.editFormListVisible = false;
      this.queryTableList();
    },
    // 修改中的点击大型车
    handleClickEditBigCar () {
      this.editBillRuleFormList.bindCarType = "大型车辆"
    },
    // 修改中的点击小型车
    handleClickEditSmallCar () {
      this.editBillRuleFormList.bindCarType = "小型车辆"
    },

    // 表格中的删除接口
    handleDelete (row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let info = [{
            billingRuleDefId: row.billingRuleDefId
          }]
          this.$charingRules.delBillingRule(info).then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.queryTableList()
          })

        })
        .catch(() => { })
    },
    // 新增中的计费规则类型改变时的方法
    handleChangeBillRuleName (value) {
      if (value == 1) {
        this.addBillRuleFormList.billRuleTypeName = "按次收费"
      }
      else if (value == 2) {
        this.addBillRuleFormList.billRuleTypeName = "按时长收费"
      }
    },
    handleSelectionChange () { },

    // 查询按钮
    handleClickQuery () {
      this.pageNum = 1;
      this.queryTableList()
    },
    // 查询收费规则列表
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$charingRules.queryBillingRuleList(this.searchForm).then((res) => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList();
    },
    // 重置
    resetForm () {
      this.searchForm = {}
      this.queryTableList()
    },
    //获取计费规则
    getChargingRules () {
      let info = {
        "columnName": ["value", "name"],
        "tableName": "tb_dict",
        "whereStr": [
          {
            "colName": "parent_id",
            "value": "EF143123NKJHI8YKLAJSFLUW3OE1OAF"
          }
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.addBillRuleDictList = response.resultEntity
      })
    },
    //获取最短计费时长
    getMinimumBillingTime () {
      let info = {
        "columnName": ["value", "name"],
        "tableName": "tb_dict",
        "whereStr": [
          {
            "colName": "parent_id",
            "value": "43E7FFC136C44EF49E3BD967C8D47F1B"
          }
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.addMinTimeDictList = response.resultEntity
      })
    },
    // 获取结束时间，设置下一行开始时间
    getPreTime () {
      let t = this.createTimeStr(new Date(), 'ymd') + ' 00:03:00'
      return t
    },
    // 将时间格式化为标准字符串==HH-MM-DD HH:MI:ss
    createTimeStr (time = new Date(), type = 'ymdhis') {
      let date = new Date(time)
      let Str = ''
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month < 10) month = '0' + month
      let day = date.getDate()
      if (day < 10) day = '0' + day
      let hours = date.getHours()
      if (hours < 10) hours = '0' + hours
      let minutes = date.getMinutes()
      if (minutes < 10) minutes = '0' + minutes
      let Seconds = date.getSeconds()
      if (Seconds < 10) Seconds = '0' + Seconds
      if (type === 'ymdhis') {
        Str = year + '-' +
          month + '-' +
          day + ' ' +
          hours + ':' +
          minutes + ':' +
          Seconds
      } else if (type === 'ymd') {
        Str = year + '-' +
          month + '-' +
          day
      } else if (type === 'his') {
        Str = hours + ':' +
          minutes + ':' +
          Seconds
      }
      return Str
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mianbody {
  height: 100%;
  .topSelectLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 101px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}

.addRow {
  .spanLine1 {
    width: 100%;
    margin-top: 10px;
    color: #f8fdff;
    font-size: 16px;
  }
  .addFormLine1 {
    margin-top: 22px;
  }
  .addFormLineXize {
    font-size: 16px;
    color: #f8fdff;
  }
  .smaBigBut {
    margin-top: 10px;
  }
  .addSmallRow {
    width: 100%;
    height: 480px;
    border: 1px solid #00487a;
    margin-top: 12px;
    .addSmallRow-daySpan {
      color: #f8fdff;
      font-size: 16px;
      margin-top: 16px;
      margin-left: 1%;
    }
    .addSmallRow-dayrow {
      margin-top: 16px;
      margin-left: 1%;
    }
  }
  .col-right {
    float: right;
    right: 0;
  }
}
.addFooter {
  display: flex;
  margin-left: 83%;
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 256px;
}
.title {
  margin: 27px 0 17px;
  font-size: 16px;
  color: #fff8fdff;
  font-weight: 500;
}
.buttonLine:hover {
  cursor: pointer;
}
</style>
